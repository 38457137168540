import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../../components/InputFields";
import { SidebarLayout } from "../../../../components/Layout";
import SettingsSidebar from "../../../../components/SettingsSidebar";
import { addMessage } from "../../../../state/reducers/appReducer";
import { updateEmail } from "../../../../state/reducers/settingsReducer";
import { RootState } from "../../../../state/store";
import { fetchBaseOptions } from "../../../../utils/fetch";
import { LoginSchema } from "../../../../utils/validators";

const UpdateEmailPage = () => {
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.settings.email);
  return (
    <SidebarLayout sidebar={<SettingsSidebar />} seo={{ title: "Update email" }}>
      <Formik
        initialValues={{ email: email, password: "" }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          fetch("/api/user/email/update", {
            ...fetchBaseOptions,
            method: "PATCH",
            body: JSON.stringify(values),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              dispatch(addMessage(j));
              if (res.ok) {
                dispatch(updateEmail(values.email));
                setFieldValue("password", "", false);
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-2">
            <Field required={true} type="email" name="email" label="Email" component={Input} />
            <Field
              required={true}
              type="password"
              name="password"
              label="Password"
              component={Input}
            />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default UpdateEmailPage;
